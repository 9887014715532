import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Clean Redux Boilerplate With Hooks",
  "author": "tzookb",
  "type": "post",
  "date": "2019-11-17T13:50:08.000Z",
  "url": "/clean-redux-boilerplate-with-hooks",
  "categories": ["javascript"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We all love Redux. Well we love the benefits of using a state management system.
The huge amount of boilerplate can be a pain in the rear.`}</p>
    <p>{`But with the "new" react hooks we now have a lot of nifty shortcuts you may consider
on using with your new redux/react code.`}</p>
    <p>{`Let's start with a full blown class component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`class Example extends React {
  render() {
    const { label, onClick } = this.props
    return <button onClick={onClick}>{label}</button>
  }
}

const mapStateToProps = state => {
  return { label: state.label }
}

const mapDispatchToProps = dispatch => {
  return { onClick: dispatch(something) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Example)
`}</code></pre>
    <p>{`As you can see above, we have a lot writing to do,
just to connect our store data and methods for this simple component.`}</p>
    <h3>{`Hooks Example`}</h3>
    <p>{`Now let's try the hooks code sample:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useSelector, useDispatch } from 'react-redux'

const Example = props => {
  const label = useSelector(store => store.label)
  const dispatch = useDispatch()
  const onClick = dispatch(something)
  return <button onClick={onClick}>{label}</button>
}
`}</code></pre>
    <p>{`So much clutter just got cleaned up here...`}</p>
    <p>{`And if you still wanna have your code split from redux,
you can always have two components like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useSelector, useDispatch } from 'react-redux'

const Example = props => {
  const label = useSelector(store => store.label)
  const dispatch = useDispatch()
  const onClick = dispatch(something)
  return <ExampleView ...{ label, onClick } />
}
const ExampleView = ({ label, onClick }) => {
  return <button onClick={onClick}>{label}</button>
}
`}</code></pre>
    <p>{`P.S. These code examples are not tested, so test them out :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      